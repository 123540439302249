import React, { useEffect, useRef, useState } from 'react';
import '../css/people.css';
import immagineGrande from '../img/PEOPLE.jpg';
import Footer from './Footer';
import BoldText from './BoldText';
import peopleSVG from '../title/people.svg';
import chefImage1 from '../img/people/chef/CHEF marta pulini.jpg';
import chefImage2 from '../img/people/chef/CHEF massimo bottura .jpg';
import chefImage3 from '../img/people/chef/CHEF Takahiko Kondo .jpg';
import designersImage1 from '../img/people/designers/DESIGNER david chipperfield.jpg';
import designersImage2 from '../img/people/designers/DESIGNER Luigi Lanzi.jpg';
import designersImage3 from '../img/people/designers/DESIGNER Matteo Thun.jpg';
import artistImage1 from '../img/people/artist/ARTIST1.jpeg';
import artistImage2 from '../img/people/artist/ARTIST2.jpg';
import SeparatoreSquare from './SeparatoreSquare';




function People() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [bottleSize, setBottleSize] = useState({ width: 180, height: 1110 });

    const chefsList = [
        'Mario Rossi', 'Luigi Bianchi', 'Giovanna Verdi',
        'Elena Neri', 'Marco Gialli', 'Anna Azzurri',
        // Aggiunta di una seconda lista identica per raddoppiare
        'Mario Rossi', 'Luigi Bianchi', 'Giovanna Verdi',
        'Elena Neri', 'Marco Gialli', 'Anna Azzurri',

        'Mario Rossi', 'Luigi Bianchi', 'Giovanna Verdi',
        'Elena Neri', 'Marco Gialli', 'Anna Azzurri',

        'Mario Rossi', 'Luigi Bianchi', 'Giovanna Verdi',
        'Elena Neri', 'Marco Gialli', 'Anna Azzurri',
    ];


    return (
        <div className="wrapper-aroundmorandi">
            <div className="immagineGrande" style={{ backgroundImage: `url(${immagineGrande})` }}>
                <div className="backdrop-blur"></div>
                <img src={peopleSVG} alt="Chi Siamo" className="overlayImagePeople" />
            </div>
            <div className="Container-image">
                <div className="contentTop">
                    <div className="contenuto ">
                        <h1 className='text-danger text-center title-chi-siamo '>i protagonisti</h1>
                        <BoldText text={`Lavoriamo con i migliori **artisti**, **chefs** e **designers** provenienti da tutto il mondo per rendere ogni evento un'esperienza unica.  Il filo conduttore che li unisce è la capacità di cogliere ispirazione anche da “territori” lontani e, attraverso un **metodo progettuale condiviso**, rielaborarla per creare qualcosa di originale. Questo rende ogni progetto di **WonderingStars** un evento che va **oltre l'ordinario**.
                                        crediamo fermamente che l'unione tra eccellenza **culinaria**, **design** e arte contemporanea innovativa possa facilitare un dialogo più ampio tra le diverse discipline **creative**. ogni collaborazione è un'opportunità per esplorare nuove visioni e per dare vita a esperienze che stimolano i sensi e arricchiscono la mente.`
                        } className='testoAColonneChiSiamo marginPeople' />



                        <div className="content-and-bottle">
                            <div className="text-content">


                                <div className="chefs-images">
                                    <div><img src={chefImage1} alt="Chef 1" /><p>marta pulini</p></div>
                                    <div><img src={chefImage2} alt="Chef 2" /><p>massimo bottura</p></div>
                                    <div><img src={chefImage3} alt="Chef 3" /><p>Takahiko Kondo</p></div>
                                </div>

                                <BoldText text={`
                            I nostri progetti culturali hanno avuto il prezioso contributo di alcuni dei più rinomati **chefs**, **designers** e artisti contemporanei del panorama internazionale. 
                            tra i nostri collaboratori più illustri,  nel campo della gastronomia, figurano chefs del calibro di **massimo bottura**, 
                            **marta pulini** e **takahiko kondo**, nel campo del **design**, **matteo thun**, **david chipperfield,**luigi lanzi ** e molti altri ancora.
                            queste collaborazioni interdisciplinari hanno arricchito le nostre iniziative, promuovendo a livello nazionale e internazionale l'arte, 
                            il design e la cultura culinaria italiana.`
                                } className='testoAColonneChiSiamo marginPeople text-chef' />

                            </div>
                        </div>

                        <SeparatoreSquare />

                        <div className="content-and-bottle">
                            <div className="text-content">
                                <div className="chefs-images">
                                    <div><img src={designersImage1} alt="designers 1" /><p>david chipperfield</p></div>
                                    <div><img src={designersImage2} alt="designers 2" /><p>luigi lanzi</p></div>
                                    <div><img src={designersImage3} alt="designers 3" /><p>matteo thun</p></div>
                                </div>

                                <BoldText text={`
                            La ricchezza e la varietà delle nostre iniziative sono direttamente legate al supporto di un 
                            vasto network di collaboratori che, con la loro competenza e passione, si dedicano a valorizzare ogni aspetto dei nostri eventi. 
                            Questi professionisti, volontari e appassionati di ogni ambito, pur non trovandosi sempre sotto i riflettori, giocano un ruolo fondamentale 
                            nell'elevare gli standard e nell'espandere le frontiere dell’ordinario in ogni evento di wonderingstars`
                                } className='testoAColonneChiSiamo marginPeople text-chef' />

                            </div>
                        </div>

                        <SeparatoreSquare />

                        <div className="content-and-bottle">
                            <div className="text-content">

                                <div className="chefs-images">
                                    <div><img src={artistImage1} alt="Chef 1" /><p>carlo zucchini</p></div>
                                    <div><img src={artistImage2} alt="Chef 2" /><p>marinella manicardi</p></div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Footer />
        </div>
    );
}

export default People;