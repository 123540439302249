import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import immagineGrande from '../img/CARD3.jpg'; // Cambia l'immagine se necessario
import immagineArticolo from '../img/home-news/AroundMorandi_BotturaZucchini_07 copy2.jpg'; // Cambia l'immagine se necessario
import Footer from './Footer';
import BoldText from './BoldText';
import GalleryComponent5 from './GalleryComponent5';

function Blog1() {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="wrapper-blog1">
            <div className="immagineGrande" style={{ backgroundImage: `url(${immagineGrande})` }}>


            </div>
            <div className="Container-image">
                <div className="contentTop">
                    <div className="contenuto ">
                        <h1 className='text-danger text-center title-chi-siamo '>Un’idea, un ricettario, un evento</h1>
                        <BoldText text={`È nato tutto da un incontro. È il 2012, e l’associazione **wonderingstars** sta lavorando a un evento a **Casa Morandi** durante **Artefiera**. 
                        Durante una conversazione con Alessia Masi, curatrice del **MAMbo**, e con **Carlo Zucchini**, garante della donazione Morandi presso il Comune di Bologna,
                         nonché assistente e frequentatore della casa del grande artista bolognese per molti anni, **Valeria Lombardo**, presidente e fondatrice di wonderingstars,
                          punta l’attenzione su un argomento, cruciale allora così come oggi: la potenza dell’ispirazione e della poetica morandiane, capaci di riverberarsi per decenni
                           non solo nell’opera degli artisti, ma anche nei lavori dei designers e artisti contemporanei.
                        Durante quella chiacchierata “dietro le quinte” viene gettato un seme, che due anni dopo finalmente germoglia in un grande evento, dedicato
                            proprio a **Giorgio Morandi**, al suo impatto sul design contemporaneo e all’universo familiare dell’artista. `
                        } className=' marginMorandi' />


                        <BoldText text={`Quest’ultimo — grazie al ricettario appartenuto alla madre e alle tre sorelle Morandi, custodito da Carlo Zucchini 
                        tra i documenti in suo possesso — è rievocato a partire dalla tavola, centro assoluto dell’atmosfera intima di quell’appartamento di 
                        via Fondazza 36 dove oggi si può visitare lo studio dell’artista ricostruito.`
                        } className=' marginMorandi' />


                        <GalleryComponent5 />


                        <BoldText text={`Organizzato e curato da wonderingstars in collaborazione con MAMbo - Museo d’Arte Moderna di Bologna, 
                        museo morandi e museo casa morandi, l’evento si svolge nell’arco di quattro giorni, dal 27 al 30 marzo 2014, prendendo il 
                        titolo di aroundmorandi – natura morta con pane e limone, a sottolineare la dimensione del viaggio, idealmente attorno a quella stessa tavola e, 
                        attraverso di essa, alla figura di uno dei più grandi artisti italiani del Novecento.o.`
                        } className=' marginMorandi mt-5' />

                        <BoldText text={`Il programma, sviluppato tra Museo Morandi, MAMBo e Casa Morandi, prevede diversi appuntamenti, ospiti prestigiosi
                         e diverse modalità di presentazione e fruizione dei materiali, delle storie, dei sapori, delle atmosfere: una mostra di oggetti di 
                         design ispirati a Morandi, tra cui le bottiglie dedicate a Morandi da **Matteo Thun**, prodotte da Venini; una performance di Carlo 
                         Zucchini, dell’attrice Marinella Manicardi (che in un monologo interpreta Maria Teresa Morandi, la più giovane delle sorelle, colei 
                            che donò le opere di Giorgio Morandi al Comune di Bologna, nominando Zucchini garante della donazione) e del pluristellato chef 
                            **Massimo Bottura**, che rilegge la lezione morandiana nella maniera a 
                        lui più congeniale: un piatto, un risotto cacio e pepe all’apparenza semplicissimo eppure ricchissimo di sfumature di sapore.`
                        } className=' marginMorandi' />

                        <BoldText text={`E ancora: un laboratorio didattico, un workshop sul colore con la color designer **Vicky Syriopoulou**, un talk con 
                        Alberto Alessi di Alessi, Marzia Corraini di Edizioni Corraini, 
                        Giuliana Cesari di Cantina Umberto Cesari e Marta Pulini di Franceschetta 58 Bibendum, moderati da Aldo Colonetti, direttore della rivista Ottagono.
                        A coronare il lungo weekend di appuntamenti morandiani, una memorabile cena presso la suggestiva Sala delle Ciminiere del MAMbo, con la chef **Marta Pulini** 
                        a reinterpretare in chiave contemporanea il ricettario della famiglia Morandi, 
                        ricettario dal quale nascerà poi un libro. Ma questa è un’altra storia — la racconteremo presto.`
                        } className=' marginMorandi' />

                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Blog1;
