import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/nebnavbar.css';
import immagineGrande from '../img/sfondo3-tagliato.png';
import immagineLato1 from '../img/home-news/AroundMorandi_BotturaZucchini_07 copy3.jpg';
import immagineLato2 from '../img/home-news/shutterstock_546339595.jpg';
import immagineLato3 from '../img/Mambo_MG_2877MOD.jpg';
import newsSVG from '../title/news-blog.png';
import Footer from './Footer';

const NeBNavbar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pastEvents = [
    {
      date: "2022",
      title: "Arte Fiera Lectures presentazione Morandi",
      description: "Pittore – Carlo Zucchini Corraini Edizioni."
    },
    {
      date: "2018",
      title: "presentazione aroundmorandi e pubblicazione al Piccolo Teatro Milano"
    },
    {
      date: "2017",
      title: "pubblicazione di “Una Straordinaria Normalita-cucina e ricette in Casa Morandi”",
      description: "a cura di wonderingstars  autt. Carlo Zucchini e Simone Sbarbati – Corraini Edizioni"
    },
    {
      date: "2016",
      title: "Le Tavole dell’Arte",
      description: "Riflessioni tra Arte e Cibo progetto di Comunicamente e Villaggio Globale International – aroundmorandi nell’esperienza dell’Istituzione Bologna Musei Laura Carlini Fanfogna, Direttrice Istituzione Bologna Musei"
    },
    {
      date: "2015",
      title: "EXPO 2015",
    },
    {
      date: "2015",
      title: "wonderingstars vincitore bando INCREDIBOL con il progetto aroundmorandi-naturamorata con pane e limone",
      link: "https://www.incredibol.net/lang/it/vincitori-incredibol/tutti-i-vincitori/wonderingstars-progetto-aroundmorandi-natura-morta-con-pane-e-limone."
    },
    {
      date: "2015",
      title: "aroundmorandi brera design District EXPO 2015",
    },
    {
      date: "2015",
      title: "aroundmorandi in SET UP Contemporary Art Fair Bologna",
    },
    {
      date: "2014",
      title: "aroundmorandi-natura morta con pane e limone” MAMbo-Museo D’Arte",
      description: "Moderna di Bologna | Museo Morandi 2015 - Chef Massimo Bottura e Marta Pulini"
    },
    {
      date: "2014",
      title: " presentazione “aroundmorandi-natura morta con pane e limone",
      description: "MOT – Museum of Contemporary Art  of Tokyo"
    },
    {
      date: "2014",
      title: "wonderingstars in Brera Design District-Salone del Mobile Milano",
    },
    {
      date: "2012",
      title: "Kawa-Bo-creative food event circuito Arte Fiera off",
      description: "Bologna chef Takahiko Kondo"
    }
  ];

  return (
    <div className="eventi-wrapper">
      <div className="immagineGrande" style={{ backgroundImage: `url(${immagineGrande})` }}>
        <div className="backdrop-blur"></div> 
        <img src={newsSVG} alt="Chi Siamo" className="overlayImageNeB" />
      </div>
      <div className="contentBelowImage Neb">
        <div className="date-bar">
          <div className="month-year">news recenti</div>
          <div className="date-line" style={{ backgroundColor: '#ED4632' }}></div>
        </div>
        <div className="event-details margin-events">
          <div className="day-number"></div>
          <div className="event-info">
          <div className="time test">Ispirazione e Design</div>

            <h1 className="title text-danger">
              <Link to="/blog1" className="title-link">Un’idea, un ricettario, un evento</Link>
            </h1>
            <p className="description-eventi">
              Nacque tutto da un incontro. Era il 2012, e all’epoca l’associazione wonderingstars 
              stava lavorando a un evento a Casa Morandi durante Artefiera.
              Durante una conversazione con Alessia Masi, curatrice del MAMbo, e con  […]
            </p>
          </div>
          <div className="event-side-image">
            <img src={immagineLato1} alt="Immagine Lato" />
          </div>
        </div>
        <div className="event-details">
          <div className="day-number"></div>
          <div className="event-info">
            <div className="time test">aroundmorandi© - natura morta con pane e limone</div>
            <h1 className="title text-danger">
              <Link to="/blog2" className="title-link">Silenzio, musica, titoli di testa - Dicembre 2024 </Link>
            </h1>
            <p className="description-eventi">
              Tornerà a dicembre 2024 - a dieci anni di distanza - una nuova edizione dell’evento del 2014 aroundmorandi© - 
              natura morta con pane e limone. La sede? Scendendo in panoramica dell’alto possiamo annunciare che […]
            </p>
          </div>
          <div className="event-side-image">
            <img src={immagineLato2} alt="Immagine Lato" />
          </div>
        </div>
        <div className="event-details">
          <div className="day-number"></div>
        </div>
        <div className="contentBelowImage">
          <div className="date-bar">
            <div className="month-year">news passate</div>
            <div className="date-line" style={{ backgroundColor: '#ED4632' }}></div>
          </div>
          {pastEvents.map((event, index) => (
            <div key={index} className="event-details">
              <div className="day-number"></div>
              <div className="event-info">
                <div className="date">{event.date}</div>
                <h1 className="title-past">{event.title}</h1>
                <p className="description">
                  {event.description}
                  {event.link && <a href={event.link} target="_blank" rel="noopener noreferrer">{event.link}</a>}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NeBNavbar;
