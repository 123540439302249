import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faTiktok, faPinterestP } from '@fortawesome/free-brands-svg-icons';
import '../css/footer.css';

function Footer() {
    
    const googleMapsUrl = "https://maps.google.com/?q=via belle arti, bologna";

    return (
        <footer className="p-0">
            <div className="container-footer">
                <div className="row justify-content-center">
                    <div className="col-footer text-center">
                        <a className="dati" href="mailto:info@example.com">
                            <FontAwesomeIcon icon={faEnvelope} />
                            <p>indirizzo email</p>
                        </a>
                    </div>
                    <div className="col-footer text-center">
                        <a className="dati" href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                            <p>via belle arti - Bologna</p>
                        </a>
                    </div>
                </div>
                <div className="row social-row justify-content-center">
                    <div className="social-icons mt-5">
                        <a href="https://www.facebook.com/" className="social-icon-facebook">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a href="https://www.instagram.com/" className="social-icon-instagram">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://www.tiktok.com/" className="social-icon-tiktok">
                            <FontAwesomeIcon icon={faTiktok} />
                        </a>
                        <a href="https://www.pinterest.com/" className="social-icon-pinterest">
                            <FontAwesomeIcon icon={faPinterestP} />
                        </a>
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col-footer text-center">
                        <p className="legal-info mb-3">Tutte le foto presenti su questo sito sono state scattate da @danieleiodice @germanaluisi @valerialombardo <br/> @paolopanzera @gucciosteria @massimilianocaria e sono protette da diritti d'autore.</p>
                        <p className="legal-info mb-3">wonderingstars associazione culturale - via delle belle arti 12 – 40126 bologna C.F.91348680371</p>
                        <a href="https://www.iubenda.com/privacy-policy/28851219" className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe mx-3" title="Privacy Policy">Privacy Policy</a>
                        <a href="https://www.iubenda.com/privacy-policy/28851219/cookie-policy" className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe" title="Cookie Policy">Cookie Policy</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
