import React, { useEffect, useRef, useState } from 'react';
import '../css/pubblicazioni.css'; // Assicurati che il percorso sia corretto.
import immagineGrande from '../img/CARD3.jpg';
import chisiamoSVG from '../title/chisiamo.svg';
import Footer from './Footer';
import BoldText from './BoldText';
import progettiSVG from '../title/pubblicazioni.svg';
import GalleryComponent4 from './GalleryComponent4';



function Pubblicazioni() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [bottleSize, setBottleSize] = useState({ width: 180, height: 1110 });



    return (
        <div className="wrapper-aroundmorandi">
            <div className="immagineGrande" style={{ backgroundImage: `url(${immagineGrande})` }}>
            <img src={progettiSVG} alt="Chi Siamo" className="overlayImagePeople" />
            </div>
            <div className="Container-image">
                <div className="contentTop">
                    <div className="contenuto ">
                        <h1 className='text-danger text-center title-chi-siamo'>Una straordinaria normalità – cucina e ricette in Casa Morandi</h1>
                        <BoldText text={`**"Una straordinaria normalità – cucina e ricette in Casa Morandi"**, 
                        pubblicato nel dicembre 2017 da Corraini Edizioni, è un'opera a cura di **wonderingstars** 
                        e scritta da **Carlo Zucchini** e **Simone Sbarbati**. Questa preziosa pubblicazione esplora 
                        l'atmosfera familiare di Giorgio Morandi, mettendo in luce come la cucina e le ricette delle 
                        sorelle **Maria Teresa**, **Anna** e **Dina Morandi**e della madre **maria maccaferri**, abbiano 
                        creato un ambiente ispiratore per l'artista.

                        Il libro illustra le ricette della famiglia Morandi, arricchite da aneddoti e testimonianze che ricostruiscono il vissuto 
                        quotidiano. Il ricettario di famiglia, custodito da Carlo Zucchini e rielaborato da wonderingstars, è stato il punto di partenza
                        per questa pubblicazione. Le ricette non sono solo una raccolta di piatti, ma una finestra sulla cultura e le tradizioni che hanno 
                        influenzato la vita dell'artista.

                        Il testo è arricchito da fotografie storiche e attuali e illustrazioni e grafiche elaborate per raccontare la vita quotidiana 
                        in Casa Morandi. Ogni ricetta è accompagnata da dettagli su come veniva preparata e condivisa nella famiglia, creando un racconto,
                        quasi cinematografico, della routine domestica. Le storie delle sorelle e della madre che gestivano la casa e supportavano giorgio 
                        morandi nella sua attività artistica, emergono come un elemento fondamentale per comprendere l'ambiente creativo che ha nutrito l'opera del maestro.

                        "Una straordinaria normalità – cucina e ricette in Casa Morandi" offre un ritratto autentico della vita familiare dei Morandi,
                        sottolineando l'importanza degli odori e dei sapori che hanno caratterizzato la loro quotidianità. Attraverso le ricette e le storie,
                        il libro celebra la semplicità e la profondità della vita di tutti i giorni, riflettendo l'essenza stessa dell'arte di Giorgio Morandi.
`
                        } className='testoAColonneChiSiamo marginMorandi' />

                        <GalleryComponent4 />

                        
                    </div>
                </div>


            </div>
            <Footer />
        </div>
    );
}

export default Pubblicazioni;