import React, { useEffect } from 'react';
import '../css/chefs.css';
import immagineGrande from '../img/sfondo3-tagliato.png';
import chefImage1 from '../img/LUIGI LANZI.jpg';
import chefImage2 from '../img/Matteo.jpg';
import chefImage3 from '../img/_DSC6304.jpg';
import Footer from './Footer';



// Lista dei nomi dei chefs per la sezione finale
const chefsList = [
    'Mario Rossi', 'Luigi Bianchi', 'Giovanna Verdi',
    'Elena Neri', 'Marco Gialli', 'Anna Azzurri',
    // Aggiunta di una seconda lista identica per raddoppiare
    'Mario Rossi', 'Luigi Bianchi', 'Giovanna Verdi',
    'Elena Neri', 'Marco Gialli', 'Anna Azzurri',

    'Mario Rossi', 'Luigi Bianchi', 'Giovanna Verdi',
    'Elena Neri', 'Marco Gialli', 'Anna Azzurri',

    'Mario Rossi', 'Luigi Bianchi', 'Giovanna Verdi',
    'Elena Neri', 'Marco Gialli', 'Anna Azzurri',
];

function Chefs() {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="chefs-page-container"> {/* Classe aggiunta per contenere tutto il contenuto */}
            <div className="immagineGrandeChiSiamo" style={{ backgroundImage: `url(${immagineGrande})` }}></div>

            <div className="content-and-bottle">
                <div className="text-content">
                    <h1 className="text-danger title-chefs">Chefs</h1>
                    <p className="testoAColonne">
                        Conosci i professionisti dietro la nostra cucina
                        Conosci i professionisti dietro la nostra cucina
                        Conosci i professionisti dietro la nostra cucina
                        Conosci i professionisti dietro la nostra cucina
                        Conosci i professionisti dietro la nostra cucina
                        Conosci i professionisti dietro la nostra cucina
                        Conosci i professionisti dietro la nostra cucina
                        Conosci i professionisti dietro la nostra cucina
                        Conosci i professionisti dietro la nostra cucina
                        Conosci i professionisti dietro la nostra cucina</p>

                    <div className="chefs-images">
                        <div><img src={chefImage1} alt="Chef 1" /><p>Nome Chef 1</p></div>
                        <div><img src={chefImage2} alt="Chef 2" /><p>Nome Chef 2</p></div>
                        <div><img src={chefImage3} alt="Chef 3" /><p>Nome Chef 3</p></div>
                    </div>
                    <div className="chefs-list">
                        {chefsList.map((chef, index) => (
                            <p key={index}>{chef}</p>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Chefs;
