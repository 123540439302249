import React, { useState, useEffect } from 'react';
import '../css/galleria.css';
import blog21 from '../img/blog2/1.jpeg';
import blog22 from '../img/blog2/2.jpeg';
import blog23 from '../img/blog2/3.jpg';
import blog24 from '../img/blog2/4.jpg';
import blog25 from '../img/blog2/5.jpg';
import blog26 from '../img/blog2/6.jpg';



function GalleryComponent4() {
  const [selectedImage, setSelectedImage] = useState(blog22);
  const [isAutoPlay, setIsAutoPlay] = useState(true); // nuovo stato per il controllo dell'autoplay

  useEffect(() => {
    if (isAutoPlay) {
      const imageArray = [blog21, blog22, blog23, blog24, blog25, blog26 ]; // Array delle immagini
      let index = imageArray.indexOf(selectedImage); // Indice dell'immagine corrente

      const intervalId = setInterval(() => {
        index = (index + 1) % imageArray.length; // Incrementa l'indice circolarmente
        setSelectedImage(imageArray[index]);
      }, 3000); // Cambia immagine ogni 3 secondi

      return () => clearInterval(intervalId); // Pulizia dell'intervallo quando il componente si smonta o aggiorna
    }
  }, [selectedImage, isAutoPlay]);

  const handleImageClick = (image) => {
    setIsAutoPlay(false); // Ferma l'autoplay quando l'utente seleziona un'immagine
    setSelectedImage(image);
  };

  return (
    <div className="gallery-container">
      <div className="image-wrapper">
        <img src={selectedImage} alt="Selected" className="main-image" />
      </div>
      <div className="thumbnail-wrapper">
        <img
          src={blog21}
          alt="Around Morandi 16"
          className={`thumbnail-image ${selectedImage === blog21 ? 'selected' : ''}`}
          onClick={() => handleImageClick(blog21)}
        />
        <img
          src={blog22}
          alt="Morandi"
          className={`thumbnail-image ${selectedImage === blog22 ? 'selected' : ''}`}
          onClick={() => handleImageClick(blog22)}
        />
        <img
          src={blog23}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === blog23 ? 'selected' : ''}`}
          onClick={() => handleImageClick(blog23)}
        />
         <img
          src={blog24}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === blog24 ? 'selected' : ''}`}
          onClick={() => handleImageClick(blog24)}
        />
         <img
          src={blog25}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === blog25 ? 'selected' : ''}`}
          onClick={() => handleImageClick(blog25)}
        />
         <img
          src={blog26}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === blog26 ? 'selected' : ''}`}
          onClick={() => handleImageClick(blog26)}
        />
       

      </div>
    </div>
  );
}

export default GalleryComponent4;
