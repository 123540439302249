import React, { useEffect } from 'react';
import '../css/eventi.css';
import immagineGrande from '../img/evento1-mod.png';
import immagineLato from '../img/evento2-ny.jpg';
import BoldText from './BoldText';
import eventiSVG from '../title/eventi.svg';
import Footer from './Footer';

const Eventi = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pastEvents = [
    {
      date: "2022",
      title: "Arte Fiera Lectures presentazione Morandi",
      description: "Pittore – Carlo Zucchini Corraini Edizioni."
    },
    {
      date: "2018",
      title: "presentazione aroundmorandi e pubblicazione al Piccolo Teatro Milano"
    },
    {
      date: "2017",
      title: "pubblicazione di “Una Straordinaria Normalita-cucina e ricette in Casa Morandi”",
      description: "a cura di wonderingstars  autt. Carlo Zucchini e Simone Sbarbati – Corraini Edizioni"
    },
    {
      date: "2016",
      title: "Le Tavole dell’Arte",
      description: "Riflessioni tra Arte e Cibo progetto di Comunicamente e Villaggio Globale International – aroundmorandi nell’esperienza dell’Istituzione Bologna Musei Laura Carlini Fanfogna, Direttrice Istituzione Bologna Musei"
    },
    {
      date: "2015",
      title: "EXPO 2015",

    },
    {
      date: "2015",
      title: "wonderingstars vincitore bando INCREDIBOL con il progetto aroundmorandi-naturamorata con pane e limone",
      link: "https://www.incredibol.net/lang/it/vincitori-incredibol/tutti-i-vincitori/wonderingstars-progetto-aroundmorandi-natura-morta-con-pane-e-limone."
    },
    {
      date: "2015",
      title: "aroundmorandi brera design District EXPO 2015",
    },
    {
      date: "2015",
      title: "aroundmorandi in SET UP Contemporary Art Fair Bologna",
    },
    {
      date: "2014",
      title: "aroundmorandi-natura morta con pane e limone” MAMbo-Museo D’Arte",
      description: "Moderna di Bologna | Museo Morandi 2015 - Chef Massimo Bottura e Marta Pulini"
    },
    {
      date: "2014",
      title: " presentazione “aroundmorandi-natura morta con pane e limone",
      description: "MOT – Museum of Contemporary Art  of Tokyo"
    },
    {
      date: "2014",
      title: "wonderingstars in Brera Design District-Salone del Mobile Milano",
    },
    {
      date: "2012",
      title: "Kawa-Bo-creative food event circuito Arte Fiera off",
      description: "Bologna chef Takahiko Kondo"
    }

  ];

  return (
    <div className="eventi-wrapper">

      <div className="immagineGrande" style={{ backgroundImage: `url(${immagineGrande})` }}>
        <div className="backdrop-blur"></div>
        <img src={eventiSVG} alt="Chi Siamo" className="overlayImageEventi" />
      </div>

      {/* Contenuto dell'evento */}
      <div className="contentBelowImage">
        <div className="date-bar">
          <div className="month-year">upcoming events</div>
          <div className="date-line"></div>
        </div>

        <div className="event-details">
          <div className="day-number">

          </div>
          <div className="event-info">
            <div className="time">2024</div>
            <h1 className="title mb-4 text-danger">aroundmorandi © - natura morta con pane e limone</h1>

            <BoldText text={`**giorgio morandi** ha ancora oggi un'influenza viva nella creatività e nell’arte contemporanea. 
            l’evento di nyc propone l’artista nella chiave inusuale della sua quotidianità, 
            per offrire al pubblico non addetto ai lavori uno sguardo originale sulla vita e sull'opera
             dell'artista e della sua città, bologna.`} className="description-eventi" />
          </div>
          {/* Immagine a lato del testo */}
          <div className="event-side-image">
            <img src={immagineLato} alt="Immagine Lato" />
          </div>
        </div>
        {/* Sezione eventi passati */}
        <div className="contentBelowImage">
          <div className="date-bar">
            <div className="month-year">past events</div>
            <div className="date-line"></div>
          </div>

          {pastEvents.map((event, index) => ( // Aggiunta dell'indice per la chiave
            <div key={index} className="event-details"> {/* Chiave unica per ogni elemento */}
              <div className="day-number">
                {/* Controlla che `day` e `number` siano definiti, altrimenti utilizza l'indice */}

              </div>
              <div className="event-info">
                <div className="date">{event.date}</div>
                <h1 className="title-past">{event.title}</h1>
                <p className="description">
                  {event.description}
                  {event.link && <a href={event.link} target="_blank" rel="noopener noreferrer">{event.link}</a>}
                </p>

              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Eventi;