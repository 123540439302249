import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='text-danger' style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1 className='text-danger'>404 - Pagina non trovata</h1>
      <p className='text-danger'>La pagina che stai cercando non esiste.</p>
      <Link to="/">Torna alla Home</Link>
    </div>
  );
}

export default NotFound;
