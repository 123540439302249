import React, { useState, useEffect } from 'react';
import '../css/galleria.css';
import pubblicazioni1 from '../img/pubblicazioni/A BLOCCO 1.gif';
import pubblicazioni2 from '../img/pubblicazioni/A BLOCCO 2.jpg';
import pubblicazioni3 from '../img/pubblicazioni/B BLOCCO 1.jpg';
import pubblicazioni4 from '../img/pubblicazioni/B BLOCCO 2.jpg';
import pubblicazioni5 from '../img/pubblicazioni/C BLOCCO 1.jpg';
import pubblicazioni6 from '../img/pubblicazioni/C BLOCCO 2.jpg';



function GalleryComponent4() {
  const [selectedImage, setSelectedImage] = useState(pubblicazioni2);
  const [isAutoPlay, setIsAutoPlay] = useState(true); // nuovo stato per il controllo dell'autoplay

  useEffect(() => {
    if (isAutoPlay) {
      const imageArray = [pubblicazioni1, pubblicazioni2, pubblicazioni3, pubblicazioni4, pubblicazioni5, pubblicazioni6 ]; // Array delle immagini
      let index = imageArray.indexOf(selectedImage); // Indice dell'immagine corrente

      const intervalId = setInterval(() => {
        index = (index + 1) % imageArray.length; // Incrementa l'indice circolarmente
        setSelectedImage(imageArray[index]);
      }, 3000); // Cambia immagine ogni 3 secondi

      return () => clearInterval(intervalId); // Pulizia dell'intervallo quando il componente si smonta o aggiorna
    }
  }, [selectedImage, isAutoPlay]);

  const handleImageClick = (image) => {
    setIsAutoPlay(false); // Ferma l'autoplay quando l'utente seleziona un'immagine
    setSelectedImage(image);
  };

  return (
    <div className="gallery-container">
      <div className="image-wrapper">
        <img src={selectedImage} alt="Selected" className="main-image" />
      </div>
      <div className="thumbnail-wrapper">
        <img
          src={pubblicazioni1}
          alt="Around Morandi 16"
          className={`thumbnail-image ${selectedImage === pubblicazioni1 ? 'selected' : ''}`}
          onClick={() => handleImageClick(pubblicazioni1)}
        />
        <img
          src={pubblicazioni2}
          alt="Morandi"
          className={`thumbnail-image ${selectedImage === pubblicazioni2 ? 'selected' : ''}`}
          onClick={() => handleImageClick(pubblicazioni2)}
        />
        <img
          src={pubblicazioni3}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === pubblicazioni3 ? 'selected' : ''}`}
          onClick={() => handleImageClick(pubblicazioni3)}
        />
         <img
          src={pubblicazioni4}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === pubblicazioni4 ? 'selected' : ''}`}
          onClick={() => handleImageClick(pubblicazioni4)}
        />
        <img
          src={pubblicazioni5}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === pubblicazioni5 ? 'selected' : ''}`}
          onClick={() => handleImageClick(pubblicazioni5)}
        />
        <img
          src={pubblicazioni6}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === pubblicazioni6 ? 'selected' : ''}`}
          onClick={() => handleImageClick(pubblicazioni6)}
        />
        

      </div>
    </div>
  );
}

export default GalleryComponent4;
