import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import immagineGrande from '../img/CARD3.jpg'; // Cambia l'immagine se necessario
import immagineArticolo from '../img/home-news/AroundMorandi_BotturaZucchini_07 copy2.jpg'; // Cambia l'immagine se necessario
import Footer from './Footer';
import BoldText from './BoldText';
import GalleryComponent6 from './GalleryComponent6';

function Blog1() {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="wrapper-blog1">
            <div className="immagineGrande" style={{ backgroundImage: `url(${immagineGrande})` }}>


            </div>
            <div className="Container-image">
                <div className="contentTop">
                    <div className="contenuto ">
                        <h1 className='text-danger text-center title-chi-siamo '>Silenzio, musica, titoli di testa - Dicembre 2024</h1>
                        <BoldText text={`Tornerà a dicembre 2024 - a dieci anni di distanza - una nuova edizione dell’evento del 2014 aroundmorandi© - natura morta con pane e limone.
                        La sede? Scendendo in panoramica dell’alto possiamo annunciare che wonderingstars e i suoi ospiti troveranno casa a **New York**, 
                        all’interno di un luogo estremamente caro al mondo dell’arte, decisamente in sintonia con l’ospitalità della famiglia Morandi. 
                        Sui particolari daremo notizie più avanti, svelando a poco a poco i dettagli della serata `
                        } className=' marginMorandi' />

                        <BoldText text={`L’ospitalità della famiglia Morandi, i segreti di famiglia racchiusi nelle ricette e nelle abitudini di tutti i giorni intorno al fuoco della cucina, 
                        la metafora della condivisione del cibo intorno ad una tavola grazie ad oggetti e gesti quotidiani, sono temi tutti molto cari a wonderingstars e ancora oggi 
                        faranno da elementi portanti di questo nuovo progetto che accompagnerà i suoi ospiti all’interno del mondo privato di **Giorgio Morandi**, già raccontato durante 
                        l’evento organizzato al MAMBo (dal 27 al 30 marzo 2014).  `
                        } className=' marginMorandi' />

                        <GalleryComponent6 />

                        <BoldText text={`Grazie ancora alla collaborazione di **Carlo Zucchini**, Garante della Donazione Morandi, da parte della sorella dell'artista Maria Teresa Morandi,
                         al **comune di Bologna** e del **Museo Morand**i, MamBO, anche oggi si partirà dalla cucina di casa Morandi e dal rito che la famiglia (la madre, Maria Maccaferri Morandi,
                             e le sue tre sorelle Anna, Dina e Maria Teresa con cui trascorse tutta la sua vita) era solita mettere in scena, apparecchiando la tavola per gli 
                             ospiti e reinterpretando le ricette di famiglia raccolte nel libro **“Una straordinaria normalità”** (C. Zucchini e S. Sbarbati, Corraini, 2017). `
                        } className=' marginMorandi mt-5' />

                        <BoldText text={`La condivisione di una cena irripetibile, dalle molte suggestioni sensoriali, rappresenterà il momento 
                        culminante di una giornata dedicata alle tante connessioni tra il lavoro artistico di Giorgio Morandi e la sua influenza sul mondo 
                        della creatività e dell’arte contemporanea. 
                        Dall’Italia arriveranno alcuni chefs stellati che presenteranno la loro visione di altrettante ricette componenti il ricettario, 
                        mentre designers da tutto il mondo si misureranno con la loro interpretazione degli oggetti che animavano il lavoro dell’artista, 
                        le bottiglie per prime. Un alfabeto di forme che lo avrebbe reso celebre come l’artista che riusciva a vedere lo straordinario nell’ordinario.`
                        } className=' marginMorandi mb-3' />

                        <BoldText text={`
                        A presto per nuove informazioni!`
                        } className=' marginMorandi' />
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Blog1;
