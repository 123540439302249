import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/morandicard.css';
import image1 from '../img/home-eventi/1.jpg';
import image2 from '../img/home-eventi/2.jpg';
import image3 from '../img/home-eventi/3.jpg';
import image5 from '../img/home-eventi/4.jpg';
import image6 from '../img/home-eventi/5.jpg';
import SeparatoreSquare from './SeparatoreSquare';

function MorandiCard() {
    const navigate = useNavigate();
    const [activeImage, setActiveImage] = useState('image1');

    const handleContinueClick = () => {
        navigate('/eventi');
    };

    const handleClickImage = (image) => {
        setActiveImage(image);
    }

    return (
        <> 

            <div className="morandi-card-container">
            
                <div className="content-container text-center">
                    <h1 className="title-morandi">eventi</h1>
                    <p className="description-morandi">
                    Dal 2011, l'associazione wonderingstars, ha dato vita a numerosi progetti culturali, con eventi a Bologna,
                    Milano e Tokyo, avvalendosi del prezioso contributo di artisti, chefs e designers di fama internazionale. 
                    Attualmente siamo al lavoro per un altro significativo evento dedicato al progetto aroundmorandi  -  
                    natura morta con pane e limone, che si terrà a New York a fine 2024

                    </p>
                    <button className="button-morandi-cards" onClick={handleContinueClick}>continua</button>

                </div>
                <div className="image-container">
                    <img src={image1} alt="Immagine di Morandi" onClick={() => handleClickImage('image1')}
                        className={`responsive-image ${activeImage === 'image1' ? 'active' : ''}`} />
                    <img src={image2} alt="Immagine Multicolore" onClick={() => handleClickImage('image2')}
                        className={`responsive-image ${activeImage === 'image2' ? 'active' : ''}`} />
                    <img src={image3} alt="Immagine del Terzo Evento" onClick={() => handleClickImage('image3')}
                        className={`responsive-image ${activeImage === 'image3' ? 'active' : ''}`} />
                    <img src={image5} alt="Immagine del Guggenheim" onClick={() => handleClickImage('image5')}
                        className={`responsive-image ${activeImage === 'image5' ? 'active' : ''}`} />
                    <img src={image6} alt="Immagine del Sesto Evento" onClick={() => handleClickImage('image6')}
                        className={`responsive-image ${activeImage === 'image6' ? 'active' : ''}`} />
                </div>
                <SeparatoreSquare />
            </div>
           
        </>
    );
}

export default MorandiCard;


