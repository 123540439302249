import React, { useRef, useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import 'bootstrap/dist/css/bootstrap.min.css';
import homeSVG from '../title/WS-BIANCO-home.png';
import '../css/homepage.css';
import suoniCucina from '../img/audio-editor-output.mp3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import CookieBot from 'react-cookiebot';


const Homepage = ({ mainRef }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [beyondHomepage, setBeyondHomepage] = useState(false);

  const scrollToMain = () => {
    if (mainRef.current) {
      const topPos = mainRef.current.getBoundingClientRect().top + window.pageYOffset;
      const offset = 50;
      window.scrollTo({
        top: topPos - offset,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    const playAudio = async () => {
      try {
        await audioRef.current.play();
        setIsPlaying(true);
      } catch (err) {
        console.error("L'autoplay non è riuscito:", err);
        setIsPlaying(false);
      }
    };
    playAudio();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const isBeyond = window.scrollY > 100;
      setBeyondHomepage(isBeyond);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='sfondo1'>
      <CookieBot domainGroupId="d999cf68-abf6-4773-acfe-a1bad66d5445" />
      <div className="sfondo-opaco">
        <img src={homeSVG} alt="Home" className="overlayHome" />
        <audio ref={audioRef} src={suoniCucina} loop />
        <button onClick={togglePlayPause} className={`audio-control ${beyondHomepage ? 'beyond-homepage' : ''}`}>
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
        </button>
        <button onClick={scrollToMain} className="navigate-button">
          <IoIosArrowDown />
        </button>
      </div>
    </div>
  );
};

export default Homepage;
