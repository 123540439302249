import React from "react";
import { useNavigate } from 'react-router-dom';
import '../css/newsblog.css';
import imageArticle1 from '../img/home-news/AroundMorandi_BotturaZucchini_07 copy2.jpg';
import imageArticle2 from '../img/home-news/shutterstock_546339595.jpg';
import imageArticle3 from '../img/tokio.jpg';
import backgroundImage from '../img/bottiglia.svg';

function NewsBlog() {
    const navigate = useNavigate(); // Utilizza useNavigate all'interno del componente

    const handleButtonClick1 = () => {
        navigate('/blog1');
    };

    const handleButtonClick2 = () => {
        navigate('/blog2');
    };

    return (
        <div className="news-blog-container">
            <div className="background-image-container">
                <img src={backgroundImage} alt="Background" />
            </div>
            <h1 className="news-blog-title text-danger">news & blog</h1>
            <div className="news-blog-layout">
                <div className="article-section">
                    <div className="article-image">
                        <img src={imageArticle1} alt="Articolo" />
                    </div>
                    <div className="article-content">
                        <div>
                            <h4 className="fs-6" style={{ marginBottom: '10px' }}>Ispirazione e Design</h4>
                            <h2 className="news-blog-title text-danger" style={{ marginBottom: '20px' }}>Un’idea, un ricettario, un evento</h2>
                            <p className="text-news" style={{ marginBottom: '30px' }}>Nacque tutto da un incontro. Era il 2012, e all’epoca l’associazione wonderingstars 
                            stava lavorando a un evento a Casa Morandi durante Artefiera.
                             Durante una conversazione con Alessia Masi, curatrice del MAMbo, e con  […]
                            </p>
                            <button className="button-morandi-cards button-news" onClick={handleButtonClick1}>continua</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="news-blog-layout">
                <div className="article-section">
                    <div className="article-image">
                        <img src={imageArticle2} alt="Articolo" />
                    </div>
                    <div className="article-content">
                        <div>
                            <h4 className="fs-6" style={{ marginBottom: '10px' }}>aroundmorandi© - natura morta con pane e limone</h4>
                            <h2 className="news-blog-title text-danger" style={{ marginBottom: '20px' }}>Silenzio, musica, titoli di testa - Dicembre 2024</h2>
                            <p className="text-news" style={{ marginBottom: '30px' }}>Tornerà a dicembre 2024 - a dieci anni di distanza - una nuova edizione dell’evento del 2014 aroundmorandi© - 
                            natura morta con pane e limone. La sede? Scendendo in panoramica dell’alto possiamo annunciare che […]
                            </p>
                            <button className="button-morandi-cards button-news" onClick={handleButtonClick2}>continua</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsBlog;
