import React from "react";
import separatore from '../logo/separatoreLogo.svg';
import '../css/separatore.css';

function Separatore () {
    return ( 
        <img src={separatore} alt="Autore" className="container-separatore" />
    )
}


export default Separatore;