import React from 'react';

const formatText = (inputText) => {
    // Utilizza regex per identificare e sostituire correttamente le parti in grassetto
    const parts = inputText.split(/(\*\*[^*]+\*\*)/g);  // Divide il testo in parti
    return parts.map((part, index) => {
        if (part.startsWith('**') && part.endsWith('**')) {
            return <strong key={index}>{part.slice(2, -2)}</strong>;  // Rimuove gli asterischi e rende il testo in grassetto
        }
        return part;  // Restituisce il testo normale per le altre parti
    });
}

const BoldText = ({ text, className }) => {
    return <div className={className}>{formatText(text)}</div>;
};

export default BoldText;
