import React from "react";
import '../css/slider.css';
import imageLogo1 from '../brand/120.png';
import imageLogo2 from '../brand/adi.png';
import imageLogo3 from '../brand/alessi.png';
import imageLogo4 from '../brand/architetti.png';
import imageLogo5 from '../brand/casone.png';
import imageLogo6 from '../brand/cityOfFood.png';
import imageLogo7 from '../brand/comune.png';
import imageLogo8 from '../brand/expo.png';
import imageLogo9 from '../brand/kartell.png';
import imageLogo10 from '../brand/mambo.png';
import imageLogo11 from '../brand/morandi.png';
import imageLogo12 from '../brand/oikos.png';
import imageLogo13 from '../brand/viabizzuno.png';
import imageLogo15 from '../brand/corraini.png';
import imageLogo16 from '../brand/frizzi.png';
import imageLogo17 from '../brand/ottagono.png';
import imageLogo18 from '../brand/monster.png';
import imageLogo19 from '../brand/domenica.png';
import imageLogo20 from '../brand/al.png';
import imageLogo21 from '../brand/frigo.png';
import imageLogo22 from '../brand/bora.png';
import imageLogo23 from '../brand/sub-zero.png';
import imageLogo24 from '../brand/osteria.png';
import imageLogo26 from '../brand/bibendum.png';
import imageLogo27 from '../brand/pellegrino.png';
import imageLogo28 from '../brand/favola.png';
import imageLogo25 from '../brand/franceschetta.png';
import imageLogo29 from '../brand/umberto.png';
import imageLogo30 from '../brand/offelleria.png';
import imageLogo31 from '../brand/sole.png';
import imageLogo32 from '../brand/grafiche.png';
import imageLogo33 from '../brand/senape.png';

export default function ScrollImagesRight({ defaultWidth = '70%', defaultHeight = 'auto' }) {
    const imageObjects = [
        { src: imageLogo1, width: '60%', height: 'auto' },
        { src: imageLogo2, width: '100%', height: 'auto' },
        { src: imageLogo3, width: '70%', height: 'auto' },
        { src: imageLogo4, width: '100%', height: 'auto' },
        { src: imageLogo5, width: '55%', height: 'auto' },
        { src: imageLogo6, width: '80%', height: 'auto' },
        { src: imageLogo7, width: '85%', height: 'auto' },
        { src: imageLogo8, width: '85%', height: 'auto' },
        { src: imageLogo9, width: '60%', height: 'auto' },
        { src: imageLogo10, width: '70%', height: 'auto' },
        { src: imageLogo11, width: '70%', height: 'auto' },
        { src: imageLogo12, width: '65%', height: 'auto' },
        { src: imageLogo13, width: '75%', height: 'auto' },
        { src: imageLogo15, width: '70%', height: 'auto' },
        { src: imageLogo16, width: '65%', height: 'auto' },
        { src: imageLogo17, width: '70%', height: 'auto' },
        { src: imageLogo18, width: '100%', height: 'auto' },
        { src: imageLogo19, width: '90%', height: 'auto' },
        { src: imageLogo20, width: '65%', height: 'auto' },
        { src: imageLogo21, width: '75%', height: 'auto' },
        { src: imageLogo22, width: '80%', height: 'auto' },
        { src: imageLogo23, width: '95%', height: 'auto' },
        { src: imageLogo24, width: '90%', height: 'auto' },
        { src: imageLogo26, width: '70%', height: 'auto' },
        { src: imageLogo27, width: '100%', height: 'auto' },
        { src: imageLogo28, width: '65%', height: 'auto' },
        { src: imageLogo25, width: '90%', height: 'auto' },
        { src: imageLogo29, width: '75%', height: 'auto' },
        { src: imageLogo30, width: '80%', height: 'auto' },
        { src: imageLogo31, width: '35%', height: 'auto' },
        { src: imageLogo32, width: '100%', height: 'auto' },
        { src: imageLogo33, width: '60%', height: 'auto' }
    ];

    const doubleImages = [...imageObjects, ...imageObjects]; // Duplica l'array per il loop continuo

    return (
        <div className="slider">
            <h1 className='text-danger title-slider title-chi-siamo'>i nostri partners</h1>
            <div className="slide-track">
                {doubleImages.map((imageObj, index) => (
                    <div className="slide" key={index}>
                        <img className='logo' src={imageObj.src} alt="" 
                             style={{ width: imageObj.width || defaultWidth, height: imageObj.height || defaultHeight }} />
                    </div>
                ))}
            </div>
        </div>
    );
}
