import React, { useState, useEffect } from 'react';
import '../css/galleria.css';
import blog1 from '../img/blog1/1.jpg';
import blog2 from '../img/blog1/2.jpeg';
import blog3 from '../img/blog1/3.jpeg';
import blog4 from '../img/blog1/4.jpeg';




function GalleryComponent4() {
  const [selectedImage, setSelectedImage] = useState(blog2);
  const [isAutoPlay, setIsAutoPlay] = useState(true); // nuovo stato per il controllo dell'autoplay

  useEffect(() => {
    if (isAutoPlay) {
      const imageArray = [blog1, blog2, blog3, blog4 ]; // Array delle immagini
      let index = imageArray.indexOf(selectedImage); // Indice dell'immagine corrente

      const intervalId = setInterval(() => {
        index = (index + 1) % imageArray.length; // Incrementa l'indice circolarmente
        setSelectedImage(imageArray[index]);
      }, 3000); // Cambia immagine ogni 3 secondi

      return () => clearInterval(intervalId); // Pulizia dell'intervallo quando il componente si smonta o aggiorna
    }
  }, [selectedImage, isAutoPlay]);

  const handleImageClick = (image) => {
    setIsAutoPlay(false); // Ferma l'autoplay quando l'utente seleziona un'immagine
    setSelectedImage(image);
  };

  return (
    <div className="gallery-container">
      <div className="image-wrapper">
        <img src={selectedImage} alt="Selected" className="main-image" />
      </div>
      <div className="thumbnail-wrapper">
        <img
          src={blog1}
          alt="Around Morandi 16"
          className={`thumbnail-image ${selectedImage === blog1 ? 'selected' : ''}`}
          onClick={() => handleImageClick(blog1)}
        />
        <img
          src={blog2}
          alt="Morandi"
          className={`thumbnail-image ${selectedImage === blog2 ? 'selected' : ''}`}
          onClick={() => handleImageClick(blog2)}
        />
        <img
          src={blog3}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === blog3 ? 'selected' : ''}`}
          onClick={() => handleImageClick(blog3)}
        />
         <img
          src={blog4}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === blog4 ? 'selected' : ''}`}
          onClick={() => handleImageClick(blog4)}
        />
       

      </div>
    </div>
  );
}

export default GalleryComponent4;
