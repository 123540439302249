import React, { useEffect, useRef, useState } from 'react';
import '../css/chisiamo.css';
import imageFondatore1 from '../img/fondatori/valeria lombardo .jpg';
import imageFondatore2 from '../img/fondatori/vincenzo.jpg';
import imageFondatore3 from '../img/fondatori/alex.jpeg';
import immagineGrande from '../img/CHI-SIAMO.jpg';
import chisiamoSVG from '../title/chi-siamo.svg';
import BoldText from './BoldText';
import Footer from './Footer';

const ChiSiamo = () => {
  // References to the title and the last image of the article
  const titleRef = useRef(null);
  const lastImageRef = useRef(null);
  const [bottleStyle, setBottleStyle] = useState({});

  const articleSections = [
    {
      title: "around morandi",
      text1: "Primo paragrafo del primo articolo...",
      text2: "Secondo paragrafo del primo articolo..."
    },
    {
      title: "another section",
      text1: "Primo paragrafo della seconda sezione...",
      text2: "Secondo paragrafo della seconda sezione..."
    },
    {
      title: "yet another section",
      text1: "Primo paragrafo della terza sezione...",
      text2: "Secondo paragrafo della terza sezione..."
    }
  ];


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <div className="wrapper">
       <div className="immagineGrande" style={{ backgroundImage: `url(${immagineGrande})` }}>
        <div className="backdrop-blur"></div> 
        <img src={chisiamoSVG} alt="Chi Siamo" className="overlayImage" />
      </div>


      <div className="contenChiSiamo">
        <div className="contentTop">
          <div className="contenuto">
            <h1 className='text-danger text-center title-chi-siamo'>Oltre l’ordinario: tra design, arte contemporanea e creative food</h1>
            <BoldText text={`Wonderingstars rappresenta un crocevia innovativo dove design, arte contemporanea e gastronomia si fondono in esperienze uniche. Nata nel 2011 dall'idea di **Valeria Lombardo**, la nostra associazione si impegna a creare progetti culturali che vanno oltre il convenzionale. La nostra missione è quella di creare ponti tra diverse discipline, coinvolgendo ospiti prestigiosi tra artisti, chefs e designers che, pur lavorando in ambiti differenti, con tecniche, strumenti e linguaggi diversi tra loro, hanno la capacità di cogliere il più piccolo spunto, anche da “territori” pratici e concettuali a loro lontani, e di riuscire, attraverso il metodo progettuale, a rielaborarlo e a farlo proprio, creando qualcosa di originale.

Questo **processo creativo** è il filo conduttore di ogni nostro progetto, che rende ogni evento un percorso irripetibile attraverso i sensi e un'esplorazione delle potenzialità espressive del cibo e del design come strumenti di narrazione e innovazione.

Negli anni, abbiamo dato vita a eventi culturali in location prestigiose e con ospiti di spicco. In particolare attraverso la nostra serie di progetti **aroundmorandi**, ci siamo impegnati a esplorare e presentare nuove interpretazioni e prospettive dell'arte meditativa del maestro **Giorgio Morandi**.
`} className="testoAColonneChiSiamo" />
          </div>
        </div>
        {/* Article sections with split paragraphs */}
        <h1 className='text-danger title-chi-siamo fondatori'>i fondatori</h1>
        <div className="article-section-chi-siamo" >
          <div className="article-image-chi-siamo">
            <img src={imageFondatore1} alt="Articolo" />
          </div>
          <div className="article-content-chi-siamo">
            <div>
              <h2 className="text-danger title-article">valeria lombardo | fondatrice e presidente</h2>
              <BoldText text={`valeria lombardo, **fondatrice e presidente di wonderingstars**: dopo gli studi classici, si laurea in **architettura**; collabora alla **biennale di venezia “less aestethics more ethics”**. si trasferisce nel 2002 a **parigi** e successivamente in provenza, dove sviluppa il suo interesse si occupa di **museografia**, interazione tra arte contemporanea, food e design, collaborando con **chefs**, **artisti** e **critici d’arte**. **global design director** per grandi aziende, ha sviluppato **worldwide**, progetti con i più grandi tra designers ed architetti internazionali.`} className='testoAColonne-p' />
            </div>
          </div>

        </div>
        <div className="article-section-chi-siamo" >
          <div className="article-image-chi-siamo">
            <img src={imageFondatore2} alt="Articolo" />
          </div>
          <div className="article-content-chi-siamo">
            <div>
              <h2 className="text-danger title-article">vincenzo adinolfi | tesoriere e vice-presidente</h2>
              <BoldText text={`vincenzo adinolfi, laureato in **discipline economiche e sociali** all'università bocconi di milano, intraprende la libera professione a **bologna** dopo brevi esperienze nel settore privato. iscritto agli albi di **revisore legale** degli enti locali e di **revisore dei conti**, ricopre ruoli di consigliere di amministrazione e liquidatore in diverse società. è anche revisore per federazioni sportive e curatore fallimentare per il tribunale di bologna, offrendo consulenza in materia **societaria, aziendale e contabile**.`} className='testoAColonne-p' />


            </div>
          </div>

        </div>
        <div className="article-section-chi-siamo margin" >

          <div className="article-image-chi-siamo">
            <img src={imageFondatore3} alt="Articolo" />
          </div>
          <div className="article-content-chi-siamo">
            <div>
              <h2 className="text-danger title-article">alexander neuwahl | features engineering</h2>
              <BoldText text={`alexander neuwahl è un professionista della progettazione e della comunicazione; **da oltre 25 anni** lavora con e per innumerevoli soggetti pubblici e privati, italiani ed esteri, nel campo della **pianificazione e dello sviluppo di contenuti**, allestimenti, comunicazione, grafica e virtuale. è specialista di **leonardo da vinci** - in merito è autore di numerose pubblicazioni - e di storia della tecnologia rinascimentale; è attivo in altri ambiti, fra i quali **l’art brut** in qualità di membro del comitato scientifico de la tinaia (firenze).`} className='testoAColonne-p' />

            </div>
          </div>

        </div>

      </div>

      <Footer />
    </div>
  );
};

export default ChiSiamo;
