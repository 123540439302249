import React, { useState, useEffect } from 'react';
import '../css/galleria.css';
import progetti1 from '../img/progetti/1.jpg';
import progetti2 from '../img/progetti/2.jpg';
import progetti3 from '../img/progetti/3.jpg';
import progetti4 from '../img/progetti/4.jpg';
import progetti5 from '../img/progetti/5.jpg';
import progetti6 from '../img/progetti/6.jpg';
import progetti7 from '../img/progetti/7.jpg';
import progetti8 from '../img/progetti/8.jpg';
import progetti9 from '../img/progetti/9.jpg';


function GalleryComponent3() {
  const [selectedImage, setSelectedImage] = useState(progetti2);
  const [isAutoPlay, setIsAutoPlay] = useState(true); // nuovo stato per il controllo dell'autoplay

  useEffect(() => {
    if (isAutoPlay) {
      const imageArray = [progetti1, progetti2, progetti3, progetti4, progetti5, progetti6, progetti7, progetti8, progetti9 ]; // Array delle immagini
      let index = imageArray.indexOf(selectedImage); // Indice dell'immagine corrente

      const intervalId = setInterval(() => {
        index = (index + 1) % imageArray.length; // Incrementa l'indice circolarmente
        setSelectedImage(imageArray[index]);
      }, 3000); // Cambia immagine ogni 3 secondi

      return () => clearInterval(intervalId); // Pulizia dell'intervallo quando il componente si smonta o aggiorna
    }
  }, [selectedImage, isAutoPlay]);

  const handleImageClick = (image) => {
    setIsAutoPlay(false); // Ferma l'autoplay quando l'utente seleziona un'immagine
    setSelectedImage(image);
  };

  return (
    <div className="gallery-container">
      <div className="image-wrapper">
        <img src={selectedImage} alt="Selected" className="main-image" />
      </div>
      <div className="thumbnail-wrapper">
        <img
          src={progetti1}
          alt="Around Morandi 16"
          className={`thumbnail-image ${selectedImage === progetti1 ? 'selected' : ''}`}
          onClick={() => handleImageClick(progetti1)}
        />
        <img
          src={progetti2}
          alt="Morandi"
          className={`thumbnail-image ${selectedImage === progetti2 ? 'selected' : ''}`}
          onClick={() => handleImageClick(progetti2)}
        />
        <img
          src={progetti3}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === progetti3 ? 'selected' : ''}`}
          onClick={() => handleImageClick(progetti3)}
        />
         <img
          src={progetti4}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === progetti4 ? 'selected' : ''}`}
          onClick={() => handleImageClick(progetti4)}
        />
        <img
          src={progetti5}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === progetti5 ? 'selected' : ''}`}
          onClick={() => handleImageClick(progetti5)}
        />
        <img
          src={progetti6}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === progetti6 ? 'selected' : ''}`}
          onClick={() => handleImageClick(progetti6)}
        />
        <img
          src={progetti7}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === progetti7 ? 'selected' : ''}`}
          onClick={() => handleImageClick(progetti7)}
        />
         <img
          src={progetti8}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === progetti8 ? 'selected' : ''}`}
          onClick={() => handleImageClick(progetti8)}
        />
         <img
          src={progetti9}
          alt="Around Morandi 17"
          className={`thumbnail-image ${selectedImage === progetti9 ? 'selected' : ''}`}
          onClick={() => handleImageClick(progetti9)}
        />

      </div>
    </div>
  );
}

export default GalleryComponent3;
