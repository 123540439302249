import React from "react";
import '../css/partners.css';
import immagineGrandePartners from '../img/PARTNERS.jpg';
import BoldText from './BoldText';
import Footer from './Footer';
import partnersSVG from '../title/partners.png';

import brandMambo from '../brand/mambo.png';
import brandMorandi from '../brand/morandi.png';
import brandComuneBologna from '../brand/comune.png';
import brandExpo from '../brand/expo.png';
import brandAdi from '../brand/adi.png';
import brandArchitetti from '../brand/architetti.png';
import brandCity from '../brand/cityOfFood.png';
import brandAlessi from '../brand/alessi.png';
import brandKartell from '../brand/kartell.png';
import brandCasone from '../brand/casone.png';
import brand120 from '../brand/120.png';
import brandViaBizzuno from '../brand/viabizzuno.png';
import brandOikos from '../brand/oikos.png';
import brandCorraini from '../brand/corraini.png';
import brandFrizzi from '../brand/frizzi.png';
import brandOttagono from '../brand/ottagono.png';
import brandMonster from '../brand/monster.png';
import brandDomenica from '../brand/domenica.png';
import brandAl from '../brand/al.png';
import brandFrigo from '../brand/frigo.png';
import brandBora from '../brand/bora.png';
import brandSubzero from '../brand/sub-zero.png';
import brandOsteria from '../brand/osteria.png';
import brandFranceschetta from '../brand/franceschetta.png';
import brandBibendum from '../brand/bibendum.png';
import brandPellegrino from '../brand/pellegrino.png';
import brandFavola from '../brand/favola.png';
import brandUmberto from '../brand/umberto.png';
import brandOffelleria from '../brand/offelleria.png';
import brandSole from '../brand/sole.png';
import brandGrafiche from '../brand/grafiche.png';
import brandSenape from '../brand/senape.png';

import '../css/partners.css';

function Partners() {
    const sections = [
        {
            brands: [
                { src: brandComuneBologna, style: { height: '90px', width: 'auto' } },
                { src: brandMambo, style: { height: '43px', width: 'auto' } },
                { src: brandMorandi, style: { height: '60px', width: 'auto' } },
       
        
            
                { src: brandExpo, style: { height: '60px', width: 'auto', marginLeft: `0px` } },
                { src: brandAdi, style: { height: '45px', width: 'auto', marginLeft: `0px` } },
                { src: brandArchitetti, style: { height: '32px', width: 'auto', marginLeft: `0px` } },
           
      
        
                { src: brandCity, style: { height: '130px', width: 'auto', marginLeft: `0px` } },
           
       
           
                { src: brandAlessi, style: { height: '100px', width: 'auto', marginLeft: '0px' } },
                { src: brandKartell, style: { height: '100px', width: 'auto', marginLeft: '0px' } },
                { src: brandCasone, style: { height: '100px', width: 'auto', marginLeft: '0px' } },
                { src: brand120, style: { height: '100px', width: 'auto', marginLeft: '0px' } },
                { src: brandViaBizzuno, style: { height: '60px', width: 'auto', marginLeft: '0px' } },
                { src: brandOikos, style: { height: '70px', width: 'auto', marginLeft: '0px' } },
       
                { src: brandCorraini, style: { height: '100px', width: 'auto', marginLeft: '0px' } },
                { src: brandFrizzi, style: { height: '100px', width: 'auto', marginLeft: '0px' } },
                { src: brandOttagono, style: { height: '90px', width: 'auto', marginLeft: '0px' } },
                { src: brandMonster, style: { height: '35px', width: 'auto', marginLeft: '0px' } },
                { src: brandDomenica, style: { height: '65px', width: 'auto', marginLeft: '0px' } },
        
                { src: brandAl, style: { height: '120px', width: 'auto', marginLeft: '0px' } },
                { src: brandFrigo, style: { height: '120px', width: 'auto', marginLeft: '0px' } },
                { src: brandBora, style: { height: '55px', width: 'auto', marginLeft: '0px' } },
                { src: brandSubzero, style: { height: '30px', width: 'auto', marginLeft: '0px' } },
        
                { src: brandOsteria, style: { height: '27px', width: 'auto', marginLeft: '0px' } },
                { src: brandFranceschetta, style: { height: '40px', width: 'auto', marginLeft: '0px' } },
                { src: brandBibendum, style: { height: '60px', width: 'auto', marginLeft: '0px' } },
         
                { src: brandPellegrino, style: { height: '42px', width: 'auto', marginLeft: '0px' } },
                { src: brandFavola, style: { height: '80px', width: 'auto', marginLeft: '0px' } },
                { src: brandUmberto, style: { height: '100px', width: 'auto', marginLeft: '0px' } },
                { src: brandOffelleria, style: { height: '120px', width: 'auto', marginLeft: '0px' } },
                { src: brandSole, style: { height: '120px', width: 'auto', marginLeft: '0px' } },
                { src: brandSenape, style: { height: '80px', width: 'auto', marginLeft: '0px' } },
                { src: brandGrafiche, style: { height: '24px', width: 'auto', marginLeft: '0px' } },
            ],
        },
    ];

    return (
        <div className="partners-container">
            <div className="immagineGrandePartners" style={{ backgroundImage: `url(${immagineGrandePartners})` }}>
                <div className="backdrop-blur"></div>
                <img src={partnersSVG} alt="Chi Siamo" className="overlayImagePartners" />
            </div>
            <div className="contenutoPartners">
                <h1 className='text-danger text-center title-chi-siamo'>introduzione</h1>
                <BoldText text={`collaboriamo attivamente con **partner pubblici e privati**, **sponsor aziendali** e **istituzioni** per portare avanti i nostri progetti culturali. gli strumenti per la fattibilità di tali progetti includono **fundraising**, **co-marketing**, **co-branding** e **merchandising**. queste collaborazioni sono fondamentali per realizzare eventi, pubblicazioni e molto altro, contribuendo al **sostegno** di future edizioni e alla promozione della **cultura italiana** nel mondo.`} className="testoAColonneChiSiamo partners" />

                <div className="grid-container">
                    {sections.map((section, index) => (
                        <div key={index} className="brands-grid">
                            {section.brands.map((brand, brandIndex) => (
                                <div key={brandIndex} className="brand-grid">
                                    <img src={brand.src} alt="Brand del partner" style={brand.style} className="partner-brand" />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Partners;
