import React, { useEffect, useRef, useState } from 'react';
import '../css/aroundmorandi.css'; // Assicurati che il percorso sia corretto.
import immagineGrande from '../img/CARD3.jpg';
import chisiamoSVG from '../title/chisiamo.svg';
import Footer from './Footer';
import BoldText from './BoldText';
import aroundSVG from '../title/around-morandi.svg';
import GalleryComponent from './GalleryComponent';
import GalleryComponent2 from './GalleryComponent2';



function AroundMorandi() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [bottleSize, setBottleSize] = useState({ width: 180, height: 1110 });



    return (
        <div className="wrapper-aroundmorandi">
            <div className="immagineGrande" style={{ backgroundImage: `url(${immagineGrande})` }}>
                <img src={aroundSVG} alt="Chi Siamo" className="overlayImageAround" />
            </div>
            <div className="Container-image">
                <div className="contentTop">
                    <div className="contenuto ">
                        <h1 className='text-danger text-center title-chi-siamo '>Si può dipingere qualsiasi cosa, basta vederla.</h1>
                        <BoldText text={`È nato tutto da un incontro. È il 2012, e l’associazione wonderingstars 
                        sta lavorando a un evento a **Casa Morandi** durante **Artefiera**. Durante una 
                        conversazione con Alessia Masi, curatrice del MAMbo, e con **Carlo Zucchini**,
                         garante della donazione Morandi presso il Comune di Bologna e frequentatore della 
                         casa del grande artista bolognese per molti anni, **Valeria Lombardo**, presidente
                          di wonderingstars, punta l’attenzione su un argomento, cruciale allora così come 
                          oggi: la potenza dell’ispirazione e della poetica morandiane, capaci di riverberarsi
                           per decenni non solo nell’opera degli artisti, ma anche nei lavori dei **designers**
                            e **artisti contemporanei**.

                            Durante quella chiacchierata “dietro alle quinte” viene gettato un seme, che due anni dopo finalmente 
                            germoglia in un grande evento, dedicato proprio a **Giorgio Morandi**, al suo impatto sul design contemporaneo
                            e all’universo familiare dell’artista. Quest’ultimo — complice il **ricettario** appartenuto alle tre sorelle
                            Morandi, rinvenuto da Carlo Zucchini tra i documenti in suo possesso — è rievocato a partire dalla tavola,
                            centro assoluto dell’atmosfera intima di quell’appartamento di via Fondazza, dove oggi si può visitare 
                            lo studio dell’artista, fedelmente ricostruito.

                            Organizzato e curato da wonderingstars in collaborazione con il **Museo d’Arte Moderna di Bologna**, l’evento si svolge nell’arco di quattro giorni, dal 27 al 30 marzo 2014, prendendo il titolo di **aroundmorandi – natura morta con pane e limone**, a sottolineare la dimensione del viaggio, idealmente attorno a quella stessa tavola e, attraverso di essa, alla figura di uno dei più grandi artisti italiani del Novecento.


                            SEO KEYWORDS: Giorgio Morandi; Carlo Zucchini; Casa Morandi; design; estetica morandiana; arte italiana; museo a Bologna; influenze artistiche; designer contemporanei; storia dell'arte; cultura italiana; influenze internazionali; cinema degli anni '60; cultura egiziana; fotografi mondiali; eventi culturali; gastronomia e arte; tavola e cultura.
`
                        } className='testoAColonneChiSiamo marginMorandi' />

                        <GalleryComponent />

                        <h1 className='text-danger text-center title-chi-siamo'>perché morandi</h1>
                        <BoldText text={`abbiamo scelto giorgio morandi perché rappresenta un'**icona dell'arte moderna** italiana, la cui opera incarna la profondità nella semplicità. morandi ha esplorato la natura morta con una delicatezza e un'intensità che stimolano una riflessione più ampia sull'essenza degli oggetti quotidiani. la sua capacità di trasformare l'ordinario in straordinario risuona con la nostra missione di esplorare le intersezioni tra arte, design e gastronomia, creando esperienze immersive che invitano al dialogo tra diverse forme d'arte e stimolano nuove percezioni sensoriali.

                        creando esperienze immersive che invitano al dialogo tra diverse forme d'arte e stimolano nuove percezioni sensoriali.`} className='testoAColonneSecondo marginMorandi' />

                        <GalleryComponent2 />
                    </div>
                </div>


            </div>
            <Footer />
        </div>
    );
}

export default AroundMorandi;