import React from "react";
import separatoreSSeparatoreSquare from '../logo/separatore-home-eventi.svg';
import '../css/separatoresquare.css';

function SeparatoreSquare () {
    return ( 
        <img src={separatoreSSeparatoreSquare} alt="Autore" className="separatoreSquare" />
    )
}


export default SeparatoreSquare;