import React, { useEffect } from 'react';
import '../css/designers.css';
import immagineGrande from '../img/sfondo3-tagliato.png';

// Supponiamo che queste siano le immagini dei chefs che vuoi mostrare
import chefImage1 from '../img/LUIGI LANZI.jpg';
import chefImage2 from '../img/Matteo.jpg';
import chefImage3 from '../img/_DSC6304.jpg';

// Lista dei nomi dei chefs per la sezione finale
const designersList = [
    'Mario Rossi', 'Luigi Bianchi', 'Giovanna Verdi',
    'Elena Neri', 'Marco Gialli', 'Anna Azzurri',
    // Aggiunta di una seconda lista identica per raddoppiare
    'Mario Rossi', 'Luigi Bianchi', 'Giovanna Verdi',
    'Elena Neri', 'Marco Gialli', 'Anna Azzurri'
];

function Chefs() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="designers-container">
            <div className="immagineGrandeChiSiamo" style={{ backgroundImage: `url(${immagineGrande})` }}></div>

            <div className="content-and-bottle">
                <div className="text-content">
                    <h1 className="text-danger title-designers">designers</h1>
                    <p className="testoAColonne">
                        Conosci i professionisti del design
                        Conosci i professionisti del design
                        Conosci i professionisti del design
                        Conosci i professionisti del design
                        Conosci i professionisti del design
                        Conosci i professionisti del design
                        Conosci i professionisti del design
                        Conosci i professionisti del design
                        Conosci i professionisti del design
                        Conosci i professionisti del design</p>

                    <div className="designers-images">
                        <div><img src={chefImage1} alt="Chef 1" /><p>Nome Chef 1</p></div>
                        <div><img src={chefImage2} alt="Chef 2" /><p>Nome Chef 2</p></div>
                        <div><img src={chefImage3} alt="Chef 3" /><p>Nome Chef 3</p></div>
                    </div>
                    <div className="designers-list">
                        {designersList.map((chef, index) => (
                            <p key={index}>{chef}</p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chefs;
