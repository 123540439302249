import React from "react";
import { useNavigate } from "react-router-dom";

import imageCard1 from '../img/CARD1.jpg';
import imageCard2 from '../img/CARD2.jpeg';
import imageCard3 from '../img/CARD3.jpg';



import '../css/cardmain.css';

const cardData = [
  {
    h5Content: "chi siamo",
    title: "l'associazione",
    description: "siamo un’associazione, composta da stelle con competenze diverse che hanno deciso di fare un passo in una direzione inconsueta. ci occupiamo di arte, design, progetti culturali, creative food ed eventi.",
    backgroundImage: imageCard1,
    navigateTo: "/chi-siamo",
  },
  {
    h5Content: "artisti chefs designers",
    title: "i protagonisti",
    description: "collaboriamo con artisti, chefs e designers di spicco provenienti da ogni parte del mondo, per trasformare ogni evento in un'esperienza unica attraverso il loro modo di interpretare il mondo del design e dell'artfood .",
    backgroundImage: imageCard2,
    buttons: [
      { text: "continua", navigateTo: "/people" },
    ],
  },
  {
    h5Content: "il progetto manifesto",
    title: "aroundmorandi",
    description: "non c'è artista del novecento che meglio di giorgio morandi abbia avuto la capacità di svelare la straordinarietà degli oggetti ordinari. Arte contemporanea, ricette e design: questo e molto altro è il progetto aroundmorandi",
    backgroundImage: imageCard3, 
    navigateTo: "/aroundmorandi",
  },
];

const CardMain = () => {
  const navigate = useNavigate(); // Hook di React Router per la navigazione programmata

  return (
    <div className="cards-container">
      {cardData.map((card, index) => (
        <div 
          key={index}
          className={`card ${index === 2 ? "full-width" : ""}`} 
          style={{ backgroundImage: `url(${card.backgroundImage})`, backgroundSize: 'cover' }}
        >
          <div className="card-content">
            <h5 className="title-small" id="size" >{card.h5Content}</h5>
            <h1 className="font-title">{card.title}</h1>
            <p className="font-paragraph paragraph">{card.description}</p>
            {card.buttons ? (
              <div className="buttons-container">
                {card.buttons.map((button, idx) => (
                  <button key={idx} className="button-cardmain" onClick={() => navigate(button.navigateTo)}>
                    {button.text}
                  </button>
                ))}
              </div>
            ) : (
              <button className="button-cardmain" onClick={() => card.navigateTo && navigate(card.navigateTo)}>
                continua
              </button>
            )}
          </div>
          
        </div> 
      ))}

    </div>
    
  );
};

export default CardMain;
