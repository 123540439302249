import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import imageLogo from '../img/logo_bianco.png';
import imageLogoScrolled from '../logo/logo_nero.png';
import '../css/navbar.css';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const closeDropdownOnClickOutside = (event) => {
      if (!event.target.closest('.navbar') && isHamburgerOpen) {
        setIsHamburgerOpen(false);  // Aggiunto controllo per chiudere il menu hamburger
      }
      if (dropdownOpen && !event.target.closest('.dropdown') && !event.target.closest('.hamburger-icon')) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener('click', closeDropdownOnClickOutside);
    document.addEventListener('touchstart', closeDropdownOnClickOutside);

    return () => {
      document.removeEventListener('click', closeDropdownOnClickOutside);
      document.removeEventListener('touchstart', closeDropdownOnClickOutside);
    };
  }, [dropdownOpen, isHamburgerOpen]);

  const toggleHamburger = () => {
    setIsHamburgerOpen(!isHamburgerOpen);
  };

  const handleDropdownItemClick = (e) => {
    e.stopPropagation(); // Stops the event from propagating further
    setDropdownOpen(null);
  };

  const handleNavigationClick = (path) => (e) => {
    e.preventDefault();
    e.stopPropagation(); // Prevents event propagation to ensure navigation works correctly
    navigate(path);
    setIsHamburgerOpen(false);  // Chiude il menu hamburger
    setDropdownOpen(null); // Ensure dropdown is closed after navigation
  };

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`} onMouseLeave={() => setIsHamburgerOpen(false)}>
      <div className="container">
        <Link to="/" className="logo-link">
          <img src={scrolled ? imageLogoScrolled : imageLogo} alt="Logo" className="logo" />
        </Link>
        <button onClick={toggleHamburger} className={`hamburger-icon ${scrolled ? 'scrolled' : ''}`}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        <ul className={`menu ${isHamburgerOpen ? 'open' : ''}`}>
          <li>
            <Link to="/chi-siamo" onClick={handleDropdownItemClick}>Chi siamo</Link>
          </li>
          <li className="dropdown" onMouseEnter={() => setDropdownOpen('progetti')}
              onMouseLeave={() => setDropdownOpen(null)}>
            <Link to="#" onClick={handleNavigationClick('/progetti')}>Progetti</Link>
            <div className={`dropdown-content ${dropdownOpen === 'progetti' ? 'show' : ''}`}>
              <Link to="/aroundmorandi" onClick={handleNavigationClick('/aroundmorandi')}>Around Morandi</Link>
              <Link to="/pubblicazioni" onClick={handleNavigationClick('/pubblicazioni')}>Pubblicazioni</Link>
              <Link to="/eventi" onClick={handleNavigationClick('/eventi')}>Eventi</Link>
            </div>
          </li>
          <li className="dropdown" onMouseEnter={() => setDropdownOpen('partners')}
              onMouseLeave={() => setDropdownOpen(null)}>
            <Link to="#" onClick={handleNavigationClick('/people')}>People</Link>
          </li>
          <li><Link to="/partners" onClick={handleNavigationClick('/partners')}>Partners</Link></li>
          <li><Link to="/news-e-blog" onClick={handleNavigationClick('/news-e-blog')}>News e Blog</Link></li>
          <li><Link to="/contatti" onClick={handleDropdownItemClick}>Contatti</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
