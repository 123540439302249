import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/progetti.css';
import immagineGrande from '../img/CARD3.jpg';
import immagineAround from '../img/aroundmorandi-blocco1/a.jpg';
import immaginePub from '../img/pubblicazioni/A BLOCCO 1.gif';
import immagineEve from '../img/home-news/shutterstock_546339595.jpg';
import Footer from './Footer';
import BoldText from './BoldText';
import progettiSVG from '../title/progetti.svg';
import SeparatoreSquare from './SeparatoreSquare';
import GalleryComponent3 from './GalleryComponent3';



function Progetti() {

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [bottleSize, setBottleSize] = useState({ width: 180, height: 1110 });

    const handleContinueClick = () => {
        navigate('/pubblicazioni');
    };

    return (
        <div className="wrapper-aroundmorandi">
            <div className="immagineGrande" style={{ backgroundImage: `url(${immagineGrande})` }}>
                <div className="backdrop-blur"></div>
                <img src={progettiSVG} alt="Chi Siamo" className="overlayImageProgetti" />
            </div>
            <div className="Container-image">
                <div className="contentTop">
                    <div className="contenuto content-layout">

                        <h1 className='text-danger text-center title-chi-siamo '>Si può dipingere qualsiasi cosa, basta vederla.</h1>
                        <BoldText text={`I nostri progetti culturali iniziano a prendere forma dall 2012 con eventi come **Mappe: noi siamo qui!** e il **Kawa-Bo**, con la partecipazione del rinomato chef Takahiko Kondo (co-executive chef Gucci Osteria), entrambi parte del circuito Arte Fiera off a Bologna.

Il 2014 ha segnato un momento importante con la vittoria del premio **incredibol** dedicato alle imprese culturali e promosso dal comune di Bologna dalla regione Emilia Romagna, e con  il progetto "aroundmorandi-natura morta con pane e limone" al MAMbo-Museo D'Arte Moderna di Bologna e Museo Morandi. Il prezioso contributo degli chef Massimo Bottura e Marta Pulini ha elevato ulteriormente l'esperienza, collegando l'arte di Giorgio Morandi con interpretazioni culinarie di alto livello.

Nel 2015, durante il Salone del Mobile a Milano, abbiamo presentato "wonderingstars in brera design district" in collaborazione con 120%lino e alessi e nello stesso anno, abbiamo partecipato alla **SET UP-ART FAIR**, una fiera d'arte contemporanea indipendente a Bologna. abbiamo inoltre contribuito anche a vari eventi culturali presso la Fabbrica del Vapore. durante l’EXPO2015, infine, abbiamo presentato la nostra prima pubblicazione **"Una straordinaria normalità – cucina e ricette in Casa Morandi"** in collaborazione con Corraini Edizioni.

Attualmente, siamo impegnati nell'organizzazione di un nuovo evento a **New York**, previsto per fine 2024, che si concentrerà sul progetto "aroundmorandi - natura morta con pane e limone". Questo evento è destinato a essere un'altra pietra miliare nella nostra continua ricerca di dialoghi creativi tra diverse discipline artistiche.

`
                        } className='testoAColonneChiSiamo marginMorandi' />

                        <GalleryComponent3 />

                        <SeparatoreSquare />


                        <div className="text-and-image">
                            <div className="text-container-progetti">
                                <h1 className='text-danger titleProgetti percheMorandi'>aroundmorandi</h1>
                                <BoldText text={`È nato tutto da un incontro. È il 2012, e l’associazione **wonderingstars** sta lavorando 
                                a un evento a **Casa Morandi** durante **Artefiera**. Durante una conversazione con **Alessia Masi**, curatrice 
                                del **MAMbo**, e con **Carlo Zucchini**, garante della donazione Morandi presso il **Comune di Bologna** e frequentatore 
                                della casa del grande artista bolognese per molti anni, **Valeria Lombardo**, presidente di **wonderingstars**,  [...]`} className='marginProgetti text-column' />
                                <button className="button-morandi buttonProgetti" onClick={() => navigate('/aroundmorandi')}>continua</button>
                            </div>
                            <img src={immagineAround} alt="Morandi Events" className="content-image" />
                        </div>


                        <div className="text-and-image pubblicazioni-margin">
                            <div className="text-container-progetti">
                                <h1 className='text-danger titleProgetti percheMorandi'>pubblicazioni</h1>
                                <BoldText text={`**"Una straordinaria normalità – cucina e ricette in Casa Morandi"**, pubblicato 
                                nel dicembre 2017 da Corraini Edizioni, è un'opera a cura di **wonderingstars** e scritta da **Carlo Zucchini** e **Simone Sbarbati**.
                                 Questa preziosa pubblicazione esplora l'atmosfera familiare di Giorgio Morandi, mettendo in luce come la cucina e le ricette delle sorelle 
                                 **Maria Teresa**, **Anna** e **Dina Morandi** e della madre **maria maccaferri**, abbiano [...]`} className=' marginProgetti text-column' />
                                <button className="button-morandi buttonProgetti" onClick={() => navigate('/pubblicazioni')}>continua</button>
                            </div>
                            <img src={immaginePub} alt="Morandi Events" className="content-image" />
                        </div>


                        <div className="text-and-image eventi-margin">
                            <div className="text-container-progetti">
                                <h1 className='text-danger titleProgetti percheMorandi'>eventi</h1>
                                <BoldText text={`Dal 2011, l'associazione wonderingstars, ha dato vita a numerosi progetti culturali,
                                 con eventi a Bologna, Milano e Tokyo, avvalendosi del prezioso contributo di artisti, chefs e designers di fama internazionale.
                                  Attualmente siamo al lavoro per un altro significativo evento dedicato al progetto aroundmorandi  -  natura morta con pane e limone,
                                   che si terrà a New York [...]`} className=' marginProgetti text-column' />
                                <button className="button-morandi buttonProgetti" onClick={() => navigate('/eventi')}>continua</button>
                            </div>
                            <img src={immagineEve} alt="Morandi Events" className="content-image" />
                        </div>
                    </div>
                </div>


            </div>
            <Footer />
        </div>
    );
}

export default Progetti;