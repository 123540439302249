import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Footer from './Footer';
import immagineGrande from '../img/contatti.jpg';
import contattiSVG from '../title/contatti.svg';
import '../css/contatti.css';

const BoldText = ({ text, className }) => {
    return <p className={className} dangerouslySetInnerHTML={{ __html: text }}></p>;
};

function Contatti() {
    const [formData, setFormData] = useState({
        name_or_company: '',
        surname: '',
        email: '',
        message: '',
    });

    const [captchaValue, setCaptchaValue] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    const validateForm = () => {
        if (!formData.name_or_company.trim()) {
            alert("Inserire un nome o la ragione sociale dell'azienda.");
            return false;
        }
        const emailRegex = /\S+@\S+\.\S+/;
        if (!emailRegex.test(formData.email)) {
            alert("Per favore inserisci un indirizzo email valido.");
            return false;
        }
        if (!captchaValue) {
            alert("Per favore completa il reCAPTCHA.");
            return false;
        }
        return true;
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        const emailParams = {
            name: formData.name_or_company,
            surname: formData.surname,
            email: formData.email,
            message: formData.message,
            'g-recaptcha-response': captchaValue,
        };

        try {
            const response = await fetch('/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(emailParams),
            });

            const data = await response.json();
            console.log('Risposta dal server:', data);
            if (data.success) {
                alert("Messaggio inviato con successo!");
                setFormData({
                    name_or_company: '',
                    surname: '',
                    email: '',
                    message: '',
                });
                setCaptchaValue(null);
            } else {
                alert("Si è verificato un errore nell'invio del messaggio.");
            }
        } catch (error) {
            console.error("Errore nell'invio del messaggio: ", error);
            alert("Si è verificato un errore nell'invio del messaggio.");
        }
    };

    return (
        <div className="contatti-container">
            <div className="immagineGrande" style={{ backgroundImage: `url(${immagineGrande})` }}>
                <div className="backdrop-blur"></div> 
                <img src={contattiSVG} alt="Chi Siamo" className="overlayImageContatti" />
            </div>
            <div className="contenuto contenutoContatti ">
                <h1 className='text-danger text-center title-chi-siamo'>contattaci</h1>
                <BoldText 
                    text={`Per informazioni, richieste o collaborazioni, ti invitiamo a contattarci via email all'indirizzo 
                    <a href="mailto:info@associazioneculturale.com">info@associazioneculturale.com</a>, 
                    oppure compilando il form di contatto qui sotto. 
                    Il team di wonderingstars ti risponderà appena possibile.`} 
                    className="testoAColonneChiSiamo" 
                />
            </div>
            <div className="form-container-shadow">
                <form className="contact-form" onSubmit={sendEmail}>
                    <div className="form-outline mb-4">
                        <input type="text" className="form-control" name="name_or_company" value={formData.name_or_company} onChange={handleChange} placeholder="Nome o Azienda" />
                    </div>
                    <div className="form-outline mb-4">
                        <input type="text" className="form-control" name="surname" value={formData.surname} onChange={handleChange} placeholder="Cognome (facoltativo)" />
                    </div>
                    <div className="form-outline mb-4">
                        <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} placeholder="Email" />
                    </div>
                    <div className="form-outline mb-4">
                        <textarea className="form-control" name="message" value={formData.message} onChange={handleChange} rows="4" placeholder="Messaggio"></textarea>
                    </div>
                    <div className="form-outline mb-4">
                        <ReCAPTCHA
                            sitekey="6LeUvfApAAAAAO1FaMsPG0bkECG7PcWyDsFV6c9U"
                            onChange={handleCaptchaChange}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">Invia</button>
                </form>
            </div>
            <Footer />
        </div>
    );
}

export default Contatti;
