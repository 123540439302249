import React, { forwardRef } from 'react';
import '../css/main.css';
import Separatore from './Separatore';

const Main = forwardRef((props, ref) => {
  return (
    <div className='container-separatore' ref={ref}>
      <Separatore />
      <div className="main-container">
        <div className="content-wrapper">
          <div className="main-content">
            <h5 className='main-title text-danger'>siamo convinti esistano ancora infiniti mondi da esplorare!</h5>
            <p className="main-text">
            il nostro campo d’azione è la relazione spontanea che esiste tra design, arte contemporanea e creative food.
            </p>
          </div>
        </div>
      </div>
    </div> 
  );
});

export default Main;
