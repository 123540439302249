import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Homepage from './components/Homepage';
import ChiSiamo from './components/ChiSiamo';
import Eventi from './components/Eventi';
import AroundMorandi from './components/AroundMorandi';
import Main from './components/Main';
import CardMain from './components/CardMain';
import MorandiCard from './components/MorandiCard';
import Separatore from './components/Separatore';
import NewsBlog from './components/NewsBlog';
import Chefs from './components/Chefs';
import Designers from './components/Designers';
import Contatti from './components/Contatti';
import Partners from './components/Partners';
import NeBNavbar from './components/NeBNavbar';
import Slider from './components/Slider';
import People from './components/People';
import Pubblicazioni from './components/Pubblicazioni';
import Progetti from './components/Progetti';
import SeparatoreSquare from './components/SeparatoreSquare';
import GalleryComponent from './components/GalleryComponent';
import GalleryComponent2 from './components/GalleryComponent2';
import GalleryComponent3 from './components/GalleryComponent3';
import Footer from './components/Footer';
import Blog1 from './components/Blog1'; 
import Blog2 from './components/Blog2'; 
import NotFound from './components/NotFound';

const App = () => {
  const mainRef = useRef(null);

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={
          <>
            <Homepage mainRef={mainRef} />
            <Main ref={mainRef} />
            <CardMain />
            <MorandiCard />
            <NewsBlog />
            <Slider />
            <Footer />
          </>
        } />
        <Route path="/chi-siamo" element={<ChiSiamo />} />
        <Route path="/eventi" element={<Eventi />} />
        <Route path="/aroundmorandi" element={<AroundMorandi />} />
        <Route path="/pubblicazioni" element={<Pubblicazioni />} />
        <Route path="/chefs" element={<Chefs />} />
        <Route path="/designers" element={<Designers />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/contatti" element={<Contatti />} />
        <Route path="/news-e-blog" element={<NeBNavbar />} />
        <Route path="/people" element={<People />} />
        <Route path="/progetti" element={<Progetti />} />
        <Route path="/blog1" element={<Blog1 />} /> 
        <Route path="/blog2" element={<Blog2 />} /> 
        <Route component={NotFound} />
        {/* Altre rotte come necessario */}
      </Routes>
    </Router>
  );
};

export default App;
